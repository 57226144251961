<template>
    <v-app>
        <v-app-bar app dense color="primary" fixed dark clipped-left>
            <v-toolbar-title>
                <v-icon color="error">mdi-lock-open-outline</v-icon>
                Authy - Decentralized authentication service
            </v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer color="primary darken-2" dark clipped app v-model="drawer"
                             v-if="$store.state.authenticationToken">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        Control Panel
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Account Settings, ...
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list>
                <v-list-item link to="/">
                    <v-list-item-icon>
                        <v-icon small>mdi-laptop</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Profile
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/profile/security">
                    <v-list-item-icon>
                        <v-icon small>mdi-lock-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Security Settings
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/admin/users" v-if="$store.state.admin">
                    <v-list-item-icon>
                        <v-icon small>fas fa-users</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            User Management
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/admin/authorities" v-if="$store.state.admin">
                    <v-list-item-icon>
                        <v-icon small>mdi-account-group-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Authority Management
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/admin/services" v-if="$store.state.admin">
                    <v-list-item-icon>
                        <v-icon small>mdi-earth</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Routes
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/logout">
                    <v-list-item-icon>
                        <v-icon small>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Sign out
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <router-view/>
        </v-main>

        <v-footer app color="primary" dark>
            <v-flex class="text-center my-1">
                <v-icon small left>far fa-copyright</v-icon>
                2019 - 2022 Made with
                <v-icon color="red">mdi-heart</v-icon>
                by SeaLife (build <span style="font-family: 'JetBrains Mono', monospace">{{ $version }}</span>)
            </v-flex>

            <v-switch style="position: absolute;bottom: 9px;" v-model="darkMode" @change="toggleDarkMode" color="white"
                      hide-details
                      prepend-icon="mdi-theme-light-dark"/>
        </v-footer>
    </v-app>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
</style>

<script>

export default {
    computed: {
        darkMode() {
            return this.$store.state.darkMode;
        }
    },
    methods: {
        toggleDarkMode() {
            this.$store.commit('toggleDarkMode');
            this.$vuetify.theme.dark = this.$store.state.darkMode;
        }
    },
    mounted() {
        this.$vuetify.theme.dark = this.$store.state.darkMode;
    }
}
</script>

<style type="text/css">
* {
    font-family: 'Varela Round', Roboto, sans-serif;
}

.swal2-container {
    font-family: Roboto, sans-serif, "Droid Sans Mono", Helvetica;
}
</style>